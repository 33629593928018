
export default function Home(props) {
  return (
    <>
      {/* Revolution Slider */}
      <div className="g-overflow-hidden">
        <div
          id="rev_slider_1014_1_wrapper"
          className="rev_slider_wrapper fullscreen-container"
          data-alias="typewriter-effect"
          data-source="gallery"
          style={{
            backgroundColor: "transparent",
            padding: "0px",
          }}
        >
          {/* START REVOLUTION SLIDER 5.4.1 fullscreen mode */}
          <div
            id="rev_slider_1014_1"
            className="rev_slider fullscreenbanner"
            style={{
              display: "none",
            }}
            data-version="5.4.1"
          >
            <ul>
              {" "}
              {/* SLIDE  */}
              <li
                data-index="rs-2800"
                data-transition="curtain-1"
                data-slotamount="default"
                data-hideafterloop="0"
                data-hideslideonmobile="off"
                data-easein="default"
                data-easeout="default"
                data-masterspeed="default"
                data-thumb="./assets/img/banner/banner-img3.jpg"
                data-rotate="0"
                data-saveperformance="off"
                data-title="Slide"
                data-param1=""
                data-param2=""
                data-param3=""
                data-param4=""
                data-param5=""
                data-param6=""
                data-param7=""
                data-param8=""
                data-param9=""
                data-param10=""
                data-description=""
              >
                {/* MAIN IMAGE */}
                <img
                  src="./assets/img/banner/banner-img3.jpg"
                  alt="Nanasi"
                  data-bgposition="center center"
                  data-bgfit="cover"
                  className="rev-slidebg"
                />
                {/* LAYERS */}

                {/* LAYER NR. 1 */}
                <div
                  className="tp-caption tp-shape tp-shapewrapper "
                  id="slide-2800-layer-7"
                  data-x="['center','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['0','0','0','0']"
                  data-width="full"
                  data-height="full"
                  data-whitespace="nowrap"
                  data-type="shape"
                  data-basealign="slide"
                  data-responsive_offset="off"
                  data-responsive="off"
                  data-frames='[{"from":"opacity:0;","speed":500,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},{"speed":5000,"to":"opacity:0;","ease":"Power4.easeInOut"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: "5",
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    borderColor: "rgba(0, 0, 0, 0)",
                    borderWidth: "0px",
                  }}
                />

                {/* LAYER NR. 2 */}
                <div
                  className="tp-caption   tp-resizeme rs-parallaxlevel-4"
                  id="slide-2800-layer-1"
                  data-x="['center','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['-100','-100','-100','-100']"
                  data-fontsize="['37','34','25','30']"
                  data-lineheight="['37','34','25','30']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-responsive_offset="on"
                  data-frames='[{"from":"y:50px;sX:1;sY:1;opacity:0;","speed":2500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['center','center','center','center']"
                  data-paddingtop="[20,20,20,20]"
                  data-paddingright="[40,40,40,40]"
                  data-paddingbottom="[20,20,20,20]"
                  data-paddingleft="[40,40,40,40]"
                  style={{
                    zIndex: "6",
                    whiteSpace: "nowrap",
                    color: "rgba(255, 255, 255, 1.00)",
                    borderTop: "2px solid #fff",
                    borderBottom: "2px solid #fff",
                  }}
                >
                  <i className="g-hidden-sm-down g-font-size-20 g-pos-rel g-top-minus-5 mr-2 icon-media-119 u-line-icon-pro" />
                  Créée en 2019
                  <i className="g-hidden-sm-down g-font-size-20 g-pos-rel g-top-minus-5 ml-2 icon-media-119 u-line-icon-pro" />
                </div>

                {/* LAYER NR. 4 */}
                <div
                  className="tp-caption   tp-resizeme rs-parallaxlevel-4"
                  id="slide-2800-layer-2"
                  data-x="['center','center','center','center']"
                  data-hoffset="['-10','-10','-10','-10']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['50','50','50','50']"
                  data-fontsize="['27','27','25','30']"
                  data-lineheight="['40','40','35','40']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-responsive_offset="on"
                  data-frames='[{"from":"y:50px;sX:1;sY:1;opacity:0;","speed":2500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['center','center','center','center']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: "8",
                    whiteSpace: "nowrap",
                    fontWeight: "400",
                    color: "rgba(255, 255, 255, 1.00)",
                    borderWidth: "0px",
                  }}
                >
                  <br />
                  Nanasi Services est une Entreprise de nettoyage et de travaux
                  publics
                  <br />
                  qui offre aux particuliers et aux professionnels
                  <br />
                  une solution personnalisée et adaptée
                </div>

                {/* LAYER NR. 3 */}
                <div
                  className="tp-caption tp-shape tp-shapewrapper  tp-resizeme rs-parallaxlevel-4"
                  id="slide-2800-layer-3"
                  data-x="['center','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['150','150','150','150']"
                  data-width="100"
                  data-height="1"
                  data-whitespace="nowrap"
                  data-type="shape"
                  data-responsive_offset="on"
                  data-frames='[{"from":"y:50px;opacity:0;","speed":2500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: "7",
                    backgroundColor: "rgba(255, 255, 255, 1.00)",
                    borderColor: "rgba(0, 0, 0, 0)",
                    borderWidth: "0px",
                  }}
                />

                {/* LAYER NR. 5 */}
                <a href="#contact-section">
                  <div
                  className="tp-caption rev-btn  tp-resizeme rs-parallaxlevel-4"
                  id="slide-2800-layer-4"
                  data-x="['right','right','center','center']"
                  data-hoffset="['630','525','-105','100']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['240','240','240','240']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="button"
                  data-actions='[{"event":"click","action":"scrollbelow","offset":"px","delay":""}]'
                  data-responsive_offset="on"
                  data-frames='[{"from":"x:-50px;opacity:0;","speed":2500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"150","ease":"Power2.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 0);bw:2px 2px 2px 2px;"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[50,50,50,50]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[50,50,50,50]"
                  style={{
                    zIndex: "9",
                    whiteSpace: "nowrap",
                    fontSize: "15px",
                    lineHeight: "46px",
                    color: "rgba(255, 255, 255, 1.00)",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderColor: "rgba(255, 255, 255, 1)",
                    borderStyle: "solid",
                    borderWidth: "2px",
                    borderRadius: "30px",
                    outline: "none",
                    boxShadow: "none",
                    boxSizing: "border-box",
                    MozBoxSizing: "border-box",
                    WebkitBoxSizing: "border-box",
                    cursor: "pointer",
                  }}
                >
                  Nous Contacter
                </div>
                </a>
                

                {/* LAYER NR. 7 */}
                <div
                  className="tp-caption rev-btn  tp-resizeme rs-parallaxlevel-4"
                  href="#services-section"
                  target="_self"
                  id="slide-2800-layer-5"
                  data-x="['left','left','center','center']"
                  data-hoffset="['630','525','105','100']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['240','240','240','240']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="button"
                  data-actions='[{"event":"click","action":"scrollbelow","offset":"px","delay":""}]'
                  data-responsive_offset="on"
                  data-frames='[{"from":"x:50px;opacity:0;","speed":2500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"150","ease":"Power2.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 0);bw:2px 2px 2px 2px;"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[50,50,50,50]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[50,50,50,50]"
                  style={{
                    zIndex: "9",
                    whiteSpace: "nowrap",
                    fontSize: "15px",
                    lineHeight: "46px",
                    color: "rgba(255, 255, 255, 1.00)",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderColor: "rgba(255, 255, 255, 1)",
                    borderStyle: "solid",
                    borderWidth: "2px",
                    borderRadius: "30px",
                    outline: "none",
                    boxShadow: "none",
                    boxSizing: "border-box",
                    MozBoxSizing: "border-box",
                    WebkitBoxSizing: "border-box",
                    cursor: "pointer",
                  }}
                >
                  Nos Services
                </div>
              </li>
              <li
                data-index="rs-2801"
                data-transition="slideup"
                data-slotamount="default"
                data-hideafterloop="0"
                data-hideslideonmobile="off"
                data-easein="default"
                data-easeout="default"
                data-masterspeed="default"
                data-thumb="./assets/img/banner/banner-img2.jpeg"
                data-rotate="0"
                data-saveperformance="off"
                data-title="Slide"
                data-param1=""
                data-param2=""
                data-param3=""
                data-param4=""
                data-param5=""
                data-param6=""
                data-param7=""
                data-param8=""
                data-param9=""
                data-param10=""
                data-description=""
              >
                {/* MAIN IMAGE */}
                <img
                  src="./assets/img/banner/banner-img2.jpeg"
                  alt=""
                  data-bgposition="center center"
                  data-bgfit="cover"
                  className="rev-slidebg"
                />
                {/* LAYERS */}

                {/* LAYER NR. 1 */}
                <div
                  className="tp-caption tp-shape tp-shapewrapper "
                  id="slide-2801-layer-7"
                  data-x="['center','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['0','0','0','0']"
                  data-width="full"
                  data-height="full"
                  data-whitespace="nowrap"
                  data-type="shape"
                  data-basealign="slide"
                  data-responsive_offset="off"
                  data-responsive="off"
                  data-frames='[{"from":"opacity:0;","speed":500,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},{"speed":5000,"to":"opacity:0;","ease":"Power4.easeInOut"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: "5",
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    borderColor: "rgba(0, 0, 0, 0)",
                    borderWidth: "0px",
                  }}
                />

                {/* LAYER NR. 2 */}
                <div
                  className="tp-caption   tp-resizeme rs-parallaxlevel-4"
                  id="slide-2801-layer-1"
                  data-x="['center','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['-100','-100','-100','-100']"
                  data-fontsize="['37','34','25','30']"
                  data-lineheight="['37','34','25','30']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[{"from":"y:50px;sX:1;sY:1;opacity:0;","speed":2500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['center','center','center','center']"
                  data-paddingtop="[20,20,20,20]"
                  data-paddingright="[40,40,40,40]"
                  data-paddingbottom="[20,20,20,20]"
                  data-paddingleft="[40,40,40,40]"
                  style={{
                    zIndex: "6",
                    whiteSpace: "nowrap",
                    color: "rgba(255, 255, 255, 1.00)",
                    borderTop: "2px solid #fff",
                    borderBottom: "2px solid #fff",
                  }}
                >
                  <i className="g-hidden-sm-down g-font-size-20 g-pos-rel g-top-minus-5 mr-2 icon-media-119 u-line-icon-pro" />
                  La qualité et la propreté à votre service
                  <i className="g-hidden-sm-down g-font-size-20 g-pos-rel g-top-minus-5 ml-2 icon-media-119 u-line-icon-pro" />
                </div>

                {/* LAYER NR. 4 */}
                <div
                  className="tp-caption   tp-resizeme rs-parallaxlevel-4"
                  id="slide-2801-layer-2"
                  data-x="['center','center','center','center']"
                  data-hoffset="['-10','-10','-10','-10']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['50','50','50','50']"
                  data-fontsize="['27','27','25','30']"
                  data-lineheight="['40','40','35','40']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[{"from":"y:50px;sX:1;sY:1;opacity:0;","speed":2500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['center','center','center','center']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: "8",
                    whiteSpace: "nowrap",
                    fontWeight: "400",
                    color: "rgba(255, 255, 255, 1.00)",
                    borderWidth: "0px",
                  }}
                >
                  Profitez des prix spéciaux dès maintenant !
                </div>

                {/* LAYER NR. 3 */}
                <div
                  className="tp-caption tp-shape tp-shapewrapper  tp-resizeme rs-parallaxlevel-4"
                  id="slide-2801-layer-3"
                  data-x="['center','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['150','150','150','150']"
                  data-width="100"
                  data-height="1"
                  data-whitespace="nowrap"
                  data-type="shape"
                  data-responsive_offset="on"
                  data-frames='[{"from":"y:50px;opacity:0;","speed":2500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: "7",
                    backgroundColor: "rgba(255, 255, 255, 1.00)",
                    borderColor: "rgba(0, 0, 0, 0)",
                    borderWidth: "0px",
                  }}
                />

                {/* LAYER NR. 5 */}
                
                <a href="#contact-section">
                <div
                  className="tp-caption rev-btn  tp-resizeme rs-parallaxlevel-4"
                  id="slide-2801-layer-4"
                  data-x="['right','right','center','center']"
                  data-hoffset="['630','525','-105','100']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['240','240','240','240']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="button"
                  data-actions='[{"event":"click","action":"scrollbelow","offset":"px","delay":""}]'
                  data-responsive_offset="on"
                  data-frames='[{"from":"x:-50px;opacity:0;","speed":2500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"150","ease":"Power2.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 0);bw:2px 2px 2px 2px;"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[50,50,50,50]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[50,50,50,50]"
                  style={{
                    zIndex: "9",
                    whiteSpace: "nowrap",
                    fontSize: "15px",
                    lineHeight: "46px",
                    color: "rgba(255, 255, 255, 1.00)",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderColor: "rgba(255, 255, 255, 1)",
                    borderStyle: "solid",
                    borderWidth: "2px",
                    borderRadius: "30px",
                    outline: "none",
                    boxShadow: "none",
                    boxSizing: "border-box",
                    MozBoxSizing: "border-box",
                    WebkitBoxSizing: "border-box",
                    cursor: "pointer",
                  }}
                >
                  Nous Contacter
                </div>
              </a>
                {/* LAYER NR. 7 */}
                <div
                  className="tp-caption rev-btn  tp-resizeme rs-parallaxlevel-4"
                  href="#services-section"
                  target="_self"
                  id="slide-2801-layer-5"
                  data-x="['left','left','center','center']"
                  data-hoffset="['630','525','105','100']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['240','240','240','240']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="button"
                  data-actions='[{"event":"click","action":"scrollbelow","offset":"px","delay":""}]'
                  data-responsive_offset="on"
                  data-frames='[{"from":"x:50px;opacity:0;","speed":2500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"150","ease":"Power2.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 0);bw:2px 2px 2px 2px;"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[50,50,50,50]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[50,50,50,50]"
                  style={{
                    zIndex: "9",
                    whiteSpace: "nowrap",
                    fontSize: "15px",
                    lineHeight: "46px",
                    color: "rgba(255, 255, 255, 1.00)",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderColor: "rgba(255, 255, 255, 1)",
                    borderStyle: "solid",
                    borderWidth: "2px",
                    borderRadius: "30px",
                    outline: "none",
                    boxShadow: "none",
                    boxSizing: "border-box",
                    MozBoxSizing: "border-box",
                    WebkitBoxSizing: "border-box",
                    cursor: "pointer",
                  }}
                >
                  Nos Services
                </div>
              </li>
              <li
                data-index="rs-2802"
                data-transition="slidedown"
                data-slotamount="default"
                data-hideafterloop="0"
                data-hideslideonmobile="off"
                data-easein="default"
                data-easeout="default"
                data-masterspeed="default"
                data-thumb="./assets/img/banner/banner-img.jpg"
                data-rotate="0"
                data-saveperformance="off"
                data-title="Slide"
                data-param1=""
                data-param2=""
                data-param3=""
                data-param4=""
                data-param5=""
                data-param6=""
                data-param7=""
                data-param8=""
                data-param9=""
                data-param10=""
                data-description=""
              >
                {/* MAIN IMAGE */}
                <img
                  src="./assets/img/banner/banner-img.jpg"
                  alt=""
                  data-bgposition="center center"
                  data-bgfit="cover"
                  className="rev-slidebg"
                />
                {/* LAYERS */}

                {/* LAYER NR. 1 */}
                <div
                  className="tp-caption tp-shape tp-shapewrapper "
                  id="slide-2802-layer-7"
                  data-x="['center','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['0','0','0','0']"
                  data-width="full"
                  data-height="full"
                  data-whitespace="nowrap"
                  data-type="shape"
                  data-basealign="slide"
                  data-responsive_offset="off"
                  data-responsive="off"
                  data-frames='[{"from":"opacity:0;","speed":500,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},{"speed":5000,"to":"opacity:0;","ease":"Power4.easeInOut"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: "5",
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    borderColor: "rgba(0, 0, 0, 0)",
                    borderWidth: "0px",
                  }}
                />

                {/* LAYER NR. 2 */}
                <div
                  className="tp-caption   tp-resizeme rs-parallaxlevel-4"
                  id="slide-2802-layer-1"
                  data-x="['center','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['-100','-100','-100','-100']"
                  data-fontsize="['37','34','25','30']"
                  data-lineheight="['37','34','25','30']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[{"from":"y:50px;sX:1;sY:1;opacity:0;","speed":2500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['center','center','center','center']"
                  data-paddingtop="[20,20,20,20]"
                  data-paddingright="[40,40,40,40]"
                  data-paddingbottom="[20,20,20,20]"
                  data-paddingleft="[40,40,40,40]"
                  style={{
                    zIndex: "6",
                    whiteSpace: "nowrap",
                    color: "rgba(255, 255, 255, 1.00)",
                    borderTop: "2px solid #fff",
                    borderBottom: "2px solid #fff",
                  }}
                >
                  <i className="g-hidden-sm-down g-font-size-20 g-pos-rel g-top-minus-5 mr-2 icon-media-119 u-line-icon-pro" />
                  La qualité et la propreté à votre service
                  <i className="g-hidden-sm-down g-font-size-20 g-pos-rel g-top-minus-5 ml-2 icon-media-119 u-line-icon-pro" />
                </div>

                {/* LAYER NR. 4 */}
                <div
                  className="tp-caption   tp-resizeme rs-parallaxlevel-4"
                  id="slide-2802-layer-2"
                  data-x="['center','center','center','center']"
                  data-hoffset="['-10','-10','-10','-10']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['50','50','50','50']"
                  data-fontsize="['27','27','25','30']"
                  data-lineheight="['40','40','35','40']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[{"from":"y:50px;sX:1;sY:1;opacity:0;","speed":2500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['center','center','center','center']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: "8",
                    whiteSpace: "nowrap",
                    fontWeight: "400",
                    color: "rgba(255, 255, 255, 1.00)",
                    borderWidth: "0px",
                  }}
                >
                  Nous proposons un service de nettoyage sur mesure pour un
                  <br />
                  entretien complet de votre maison ou bureau.
                </div>

                {/* LAYER NR. 3 */}
                <div
                  className="tp-caption tp-shape tp-shapewrapper  tp-resizeme rs-parallaxlevel-4"
                  id="slide-2802-layer-3"
                  data-x="['center','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['150','150','150','150']"
                  data-width="100"
                  data-height="1"
                  data-whitespace="nowrap"
                  data-type="shape"
                  data-responsive_offset="on"
                  data-frames='[{"from":"y:50px;opacity:0;","speed":2500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: "7",
                    backgroundColor: "rgba(255, 255, 255, 1.00)",
                    borderColor: "rgba(0, 0, 0, 0)",
                    borderWidth: "0px",
                  }}
                />

                {/* LAYER NR. 5 */}
                
                <a href="#contact-section">
                <div
                  className="tp-caption rev-btn  tp-resizeme rs-parallaxlevel-4"
                  id="slide-2802-layer-4"
                  data-x="['right','right','center','center']"
                  data-hoffset="['630','525','-105','100']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['240','240','240','240']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="button"
                  data-actions='[{"event":"click","action":"scrollbelow","offset":"px","delay":""}]'
                  data-responsive_offset="on"
                  data-frames='[{"from":"x:-50px;opacity:0;","speed":2500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"150","ease":"Power2.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 0);bw:2px 2px 2px 2px;"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[50,50,50,50]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[50,50,50,50]"
                  style={{
                    zIndex: "9",
                    whiteSpace: "nowrap",
                    fontSize: "15px",
                    lineHeight: "46px",
                    color: "rgba(255, 255, 255, 1.00)",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderColor: "rgba(255, 255, 255, 1)",
                    borderStyle: "solid",
                    borderWidth: "2px",
                    borderRadius: "30px",
                    outline: "none",
                    boxShadow: "none",
                    boxSizing: "border-box",
                    MozBoxSizing: "border-box",
                    WebkitBoxSizing: "border-box",
                    cursor: "pointer",
                  }}
                >
                  Nous Contacter
                </div>
              </a>
                {/* LAYER NR. 7 */}
                <div
                  className="tp-caption rev-btn  tp-resizeme rs-parallaxlevel-4"
                  href="#services-section"
                  target="_self"
                  id="slide-2802-layer-5"
                  data-x="['left','left','center','center']"
                  data-hoffset="['630','525','105','100']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['240','240','240','240']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="button"
                  data-actions='[{"event":"click","action":"scrollbelow","offset":"px","delay":""}]'
                  data-responsive_offset="on"
                  data-frames='[{"from":"x:50px;opacity:0;","speed":2500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"150","ease":"Power2.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 0);bw:2px 2px 2px 2px;"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[50,50,50,50]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[50,50,50,50]"
                  style={{
                    zIndex: "9",
                    whiteSpace: "nowrap",
                    fontSize: "15px",
                    lineHeight: "46px",
                    color: "rgba(255, 255, 255, 1.00)",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderColor: "rgba(255, 255, 255, 1)",
                    borderStyle: "solid",
                    borderWidth: "2px",
                    borderRadius: "30px",
                    outline: "none",
                    boxShadow: "none",
                    boxSizing: "border-box",
                    MozBoxSizing: "border-box",
                    WebkitBoxSizing: "border-box",
                    cursor: "pointer",
                  }}
                >
                  Nos Services
                </div>
              </li>
            </ul>
            <div
              className="tp-bannertimer"
              style={{
                height: "2px",
                backgroundColor: "rgba(255, 255, 255, .5)",
              }}
            />
          </div>
        </div>
      </div>
      {/* End Revolution Slider */}
    </>
  );
}
