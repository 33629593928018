import React from "react";


export default function About(props) {
  return (
    <>
      {/* About */}
      <div id="about-section" className="g-pt-30 g-overflow-hidden">
        <div className="container g-pt-100">
          {/* Heading */}
          <div className="g-max-width-550 text-center mx-auto g-mb-100">
            <h1 className="text-uppercase g-color-main-light-v1 g-font-weight-600 g-font-size-13 g-letter-spacing-2 mb-4">
              A Propos de Nous
            </h1>
            <p className="g-font-size-16">
              Créée en 2019, Nanasi Services est une entreprise de nettoyage et
              d’entretien destinée aux particuliers et aux professionnels.
            </p>
          </div>
          {/* End Heading */}

          <div className="g-pb-70">
            {/* Heading */}
            <div className="g-max-width-550 text-center mx-auto g-mb-100">
              <div className="d-inline-block g-width-60 g-height-2 g-bg-secondary-light-v1 mb-3" />
              <h2 className="h3">
                une solution personnalisée pour l’entretien de leur maison et
                bureau grâce au personnel hautement qualifié.
              </h2>
            </div>
            {/* End Heading */}

            {/* Vision & Mission */}
            <div className="g-pos-rel">
              <div className="row justify-content-between">
                <div className="col-lg-5 g-mb-30">
                  <div className="g-mt-20 mb-5">
                    <h3 className="h6 text-uppercase g-color-main-light-v1 g-font-weight-600 g-letter-spacing-2 mb-3">
                      Vision
                    </h3>
                    <h2 className="mb-4">Notre Vision et Mission</h2>
                    <p className="g-line-height-2">
                      Nous disposons de véritables spécialistes dynamiques et
                      motivé(e)s pour répondre à vos demandes et besoins avec
                      toute l’efficacité attendue.
                    </p>
                  </div>

                  {/* Icon Block */}
                  <div className="media mb-4">
                    <div className="d-flex mr-4">
                      <span className="u-icon-v3 g-width-50 g-height-50 g-color-bluegray g-bg-bluegray-opacity-0_1 g-font-size-16 rounded-circle">
                        <i className="  icon-finance-047 u-line-icon-pro  g-font-size-28" />
                      </span>
                    </div>
                    <div className="media-body">
                      <span className="g-font-weight-700">La Satisfaction</span>
                      <p className="g-text">
                        Une clientèle satisfaite, est la principale clé de notre
                        réussite.
                      </p>
                    </div>
                  </div>
                  {/* End Icon Block */}

                  {/* Icon Block */}
                  <div className="media mb-4">
                    <div className="d-flex mr-4">
                      <span className="u-icon-v3 g-width-50 g-height-50 g-color-primary g-bg-primary-opacity-0_1 g-font-size-16 rounded-circle">
                        <i className="icon-education-031  u-line-icon-pro g-font-size-28" />
                      </span>
                    </div>
                    <div className="media-body">
                      <span className="g-font-weight-700">La Qualité</span>
                      <p className="g-text">
                        Les clients fidèles ne font pas que revenir vers nous,
                        ils nous recommandent à leurs proches.
                      </p>
                    </div>
                  </div>
                  {/* End Icon Block */}

                  {/* Icon Block */}
                  <div className="media mb-4">
                    <div className="d-flex mr-4">
                      <span className="u-icon-v3 g-width-50 g-height-50 g-color-red g-bg-red-opacity-0_1 g-font-size-16 rounded-circle">
                        <i className="g-font-size-28 icon-finance-012 u-line-icon-pro" />
                      </span>
                    </div>
                    <div className="media-body">
                      <span className="g-font-weight-700">L'Efficacité</span>
                      <p className="g-text">
                        Nous disposons d'une équipe dynamiques, ce qui fait
                        notre force.
                      </p>
                    </div>
                  </div>
                  {/* End Icon Block */}
                </div>
              </div>

              <div className="col-lg-6 g-pos-abs--lg g-top-0--lg g-right-0--lg g-mb-30">
                <img
                  className="w-100 g-width-auto--lg rounded-sm"
                  src="./assets/img/about-img2.jpg"
                  alt="About"
                />
              </div>
            </div>
            {/* End Vision & Mission */}
          </div>
        </div>
      </div>
      {/* End About */}
    </>
  );
}
