import React, {useEffect} from 'react'
import { useHistory } from "react-router-dom";



const { $ } = window;

export const goTo = (history, path) => {
  return (e) => {
    e.preventDefault();
    history.push(path);}
};

export default function Header(props) {
  const history = useHistory();
  useEffect(() => {
    $.HSCore.components.HSHeader.init($("#js-header"));
  }, []);
  return (
    <>
      {/* Header */}
      <header
        id="js-header"
        className="u-header u-header--sticky-top u-header--change-appearance u-header--change-logo"
        data-header-fix-moment="0"
      >
        <div
          className="u-header__section g-bg-black-opacity-0_2 g-transition-0_3 g-py-7 g-py-23--md"
          data-header-fix-moment-exclude="g-bg-black-opacity-0_5 g-py-23--md"
          data-header-fix-moment-classes="u-shadow-v19 g-bg-white g-py-15--md"
        >
          <nav className="navbar navbar-expand-lg g-py-0">
            <div className="container g-pos-rel">
              {/* Logo */}
              <a
                href="/"
                onClick={()=>goTo(history, "/")}
                className="js-go-to navbar-brand u-header__logo"
              >
                <img
                  className="u-header__logo-img u-header__logo-img--main g-width-80"
                  src={"logo192-dark.png"}
                  alt="Logo"
                />
                <img
                  className="u-header__logo-img g-width-80"
                  src={"logo192.png"}
                  alt="Logo"
                />
              </a>
              {/* End Logo */}

              {/* Navigation */}
              <div
                className="collapse navbar-collapse align-items-center flex-sm-row"
                id="navBar"
                data-mobile-scroll-hide="true"
              >
                <ul
                  id="js-scroll-nav"
                  className="navbar-nav text-uppercase g-letter-spacing-1 g-font-size-12 g-pt-20 g-pt-0--lg ml-auto"
                >
                  <li className="nav-item g-mr-15--lg g-mb-7 g-mb-0--lg">
                    <a href="#home-section" className="nav-link p-0">
                      Home <span className="sr-only">(current)</span>
                    </a>
                  </li>
                  <li className="nav-item g-mx-15--lg g-mb-7 g-mb-0--lg">
                    <a href="#about-section" className="nav-link p-0">
                      A propos de nous
                    </a>
                  </li>
                  <li className="nav-item g-mx-15--lg g-mb-7 g-mb-0--lg">
                    <a href="#services-section" className="nav-link p-0">
                      Nos Services
                    </a>
                  </li>
                  <li className="nav-item g-mx-15--lg g-mb-7 g-mb-0--lg">
                    <a href="#contact-section" className="nav-link p-0">
                      Nous Contacter
                    </a>
                  </li>
                </ul>
              </div>
              {/* End Navigation */}

              {/* Responsive Toggle Button */}
              <button
                className="navbar-toggler btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-top-3 g-right-0"
                type="button"
                aria-label="Toggle navigation"
                aria-expanded="false"
                aria-controls="navBar"
                data-toggle="collapse"
                data-target="#navBar"
              >
                <span className="hamburger hamburger--slider">
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </span>
              </button>
              {/* End Responsive Toggle Button */}
            </div>
          </nav>
        </div>
      </header>
      {/* End Header */}
    </>
  );
}
