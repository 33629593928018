import React, {useState} from 'react'

import axios from "axios";

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
const {grecaptcha} = window;
const Contact = () => {

    const [successMsg, setSuccessMsg] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        subject: "",
        message: ""
    });
    const handleChange = (name) => (e) => {
        setUserData({...userData, [name]: e.target.value});
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMsg(false);
        grecaptcha.ready(function () {
            grecaptcha.execute(RECAPTCHA_KEY, {action: 'submit'})
                .then(function (token) {

                    axios
                        .post("/api/data/contact", {
                            contact: userData,
                            captcha: token,
                        })
                        //  .post(`localhost:5000/api/contact`, userData)
                        .then(
                            (res) => {
                                setSuccessMsg(true);
                                console.log(res);
                                console.log(res.data);
                                setTimeout(() => {
                                    setSuccessMsg(false);
                                }, 5000);
                                e.target.reset();
                            },
                            (error) => {
                                console.log("FAILED...", error.response.data);
                                setErrorMessage(error.response.data.message);
                            }
                        );
                });
        });
    };
    // console.log(userData);
    return (
      <div className="contact">
        {/* <!-- Contact --> */}
        <footer id="contact-section" className="g-pos-rel">
          {/* <!-- Content --> */}
          <div
            className="g-bg-size-cover g-bg-img-hero u-bg-overlay g-bg-black-opacity-0_7--after g-pt-120 g-pb-70"
            style={{
              backgroundImage: "url(assets/img/contact-img.jpg)",
            }}
          >
            <div className="container u-bg-overlay__inner">
              <div className="row align-items-center">
                <div className="col-md-4 g-mb-50">
                  <h3 className="h4 g-color-white mb-4">Contact Info</h3>

                  {/* <!-- Icon Block --> */}
                  <div className="media align-items-center mb-4">
                    <div className="d-flex">
                      <span className="u-icon-v1 u-icon-size--sm g-color-white mr-2">
                        <i className="icon-hotel-restaurant-235 u-line-icon-pro" />
                      </span>
                    </div>
                    <div className="media-body">
                      <p className="g-color-white-opacity-0_6 mb-0">
                        47 Rue Hoche Montreuil, 93100 France
                      </p>
                    </div>
                  </div>
                  {/* <!-- End Icon Block --> */}

                  {/* <!-- Icon Block --> */}
                  <div className="media align-items-center mb-4">
                    <div className="d-flex">
                      <span className="u-icon-v1 u-icon-size--sm g-color-white mr-2">
                        <i className="icon-communication-033 u-line-icon-pro" />
                      </span>
                    </div>
                    <div className="media-body">
                      <p className="g-color-white-opacity-0_6 mb-0">
                        +33 (0) 616 319 323
                      </p>
                    </div>
                  </div>
                  {/* <!-- End Icon Block --> */}

                  {/* <!-- Icon Block --> */}
                  <div className="media align-items-center g-mb-60">
                    <div className="d-flex">
                      <span className="u-icon-v1 u-icon-size--sm g-color-white mr-2">
                        <i className="icon-communication-062 u-line-icon-pro" />
                      </span>
                    </div>
                    <div className="media-body">
                      <p className="g-color-white-opacity-0_6 mb-0">
                        nanasi.services@gmail.com
                      </p>
                    </div>
                  </div>
                  {/* <!-- End Icon Block --> */}

                  {/* <!-- Social Icons --> */}
                  <h3 className="h4 g-color-white">Réseaux Sociaux</h3>

                  <ul className="list-inline mb-0">
                    <li className="list-inline-item g-mx-2">
                      <a
                        className="u-icon-v1 u-icon-size--sm g-color-white g-bg-white-opacity-0_1 g-bg-primary--hover g-font-size-13 rounded-circle"
                        href="https://www.facebook.com/Nservices75"
                      >
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li className="list-inline-item g-mx-2">
                      <a
                        className="u-icon-v1 u-icon-size--sm g-color-white g-bg-white-opacity-0_1 g-bg-primary--hover g-font-size-13 rounded-circle"
                        href="https://twitter.com/"
                      >
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li className="list-inline-item g-mx-2">
                      <a
                        className="u-icon-v1 u-icon-size--sm g-color-white g-bg-white-opacity-0_1 g-bg-primary--hover g-font-size-13 rounded-circle"
                        href="https://instagram.com/"
                      >
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                  </ul>
                  {/* <!-- End Social Icons --> */}
                </div>

                <div className="col-md-8 g-mb-50">
                  <div className="g-brd-around g-brd-white-opacity-0_6 g-px-45 g-py-60">
                    <div className="row">
                      <div className="col-md-4 g-mb-30">
                        <h2 className="h1 g-color-white">Nous Contacter</h2>
                      </div>

                      <div className="col-md-8 g-mb-30">
                        {successMsg && (
                          <div
                            className={`alert alert-dismissible fade show ${
                              Boolean(errorMessage) ? "g-bg-red" : "g-bg-teal"
                            } g-color-white rounded-0`}
                            role="alert"
                          >
                            <button
                              type="button"
                              className="close u-alert-close--light"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>

                            <div className="media">
                              <span className="d-flex g-mr-10 g-mt-5">
                                <i className="icon-check g-font-size-25" />
                              </span>
                              <span className="media-body align-self-center">
                                {!Boolean(errorMessage) ? (
                                  <>
                                    <strong>Bien Reçu!</strong> Merci, Nous
                                    Reviendrons vers vous dans les meilleurs
                                    delais.
                                  </>
                                ) : (
                                  errorMessage
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                        {/* <!-- Contact Form --> */}
                        <form onSubmit={handleSubmit}>
                          <div className="mb-3">
                            <input
                              className="form-control g-brd-none g-brd-bottom g-brd-white g-brd-primary--focus g-color-white g-bg-transparent g-placeholder-gray-light-v5 rounded-0 g-py-13 g-px-0 mb-2"
                              type="text"
                              name="firstName"
                              required
                              onChange={handleChange("firstName")}
                              placeholder="Prénom"
                            />
                          </div>
                          <div className="mb-3">
                            <input
                              className="form-control g-brd-none g-brd-bottom g-brd-white g-brd-primary--focus g-color-white g-bg-transparent g-placeholder-gray-light-v5 rounded-0 g-py-13 g-px-0 mb-2"
                              type="text"
                              name="lastName"
                              required
                              onChange={handleChange("lastName")}
                              placeholder="Nom"
                            />
                          </div>

                          <div className="mb-3">
                            <input
                              className="form-control g-brd-none g-brd-bottom g-brd-white g-brd-primary--focus g-color-white g-bg-transparent g-placeholder-gray-light-v5 rounded-0 g-py-13 g-px-0 mb-2"
                              type="email"
                              name="email"
                              required
                              onChange={handleChange("email")}
                              placeholder="Email"
                            />
                          </div>

                          <div className="mb-3">
                            <input
                              className="form-control g-brd-none g-brd-bottom g-brd-white g-brd-primary--focus g-color-white g-bg-transparent g-placeholder-gray-light-v5 rounded-0 g-py-13 g-px-0 mb-2"
                              type="phone"
                              name="phone"
                              onChange={handleChange("phone")}
                              placeholder="Téléphone"
                            />
                          </div>

                          <div className="mb-3">
                            <select
                              className="form-control rounded-0 g-brd-none g-brd-bottom g-brd-white   g-bg-transparent g-placeholder-gray-light-v5 rounded-0 g-py-13 g-px-0 mb-2"
                              style={{
                                color: "#B9F9F1",
                              }}
                              name="subject"
                              required
                              onChange={handleChange("subject")}
                            >
                              <option
                                defaultValue
                                className="g-color-black g-bg-gray-light-v5"
                                value=""
                              >
                                Choisissez un motif
                              </option>
                              <option className="g-color-black g-bg-gray-light-v5">
                                Demande de devis
                              </option>
                              <option className="g-color-black g-bg-gray-light-v5">
                                Demande d’informations
                              </option>
                              <option className="g-color-black g-bg-gray-light-v5">
                                Prise de contact
                              </option>
                              <option className="g-color-black g-bg-gray-light-v5">
                                Autres
                              </option>
                            </select>
                          </div>

                          <div className="mb-4">
                            <textarea
                              className="form-control g-brd-none g-brd-bottom g-brd-white g-brd-primary--focus g-color-white g-bg-transparent g-placeholder-gray-light-v5 g-resize-none rounded-0 g-py-13 g-px-0 mb-5"
                              rows="5"
                              name="message"
                              required
                              onChange={handleChange("message")}
                              placeholder="Message"
                            />
                          </div>

                          <button
                            className="btn u-btn-primary g-bg-secondary g-color-primary g-color-white--hover g-bg-primary--hover g-font-weight-600 g-font-size-12 g-rounded-30 g-py-15 g-px-35"
                            type="submit"
                          >
                            Envoyer
                          </button>
                        </form>
                        {/* <!-- End Contact Form --> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Content --> */}

          {/* <!-- Go To Top --> */}
          <a
            className="js-go-to text-center g-color-main g-color-primary--hover g-left-50x g-ml-minus-100"
            href="#js-header"
            data-type="absolute"
            data-position='{
             "bottom": 65
           }'
            data-offset-top="400"
            data-compensation="#js-header"
            data-show-effect="fadeInUp"
          >
            <svg
              version="1.1"
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="208px"
              height="50px"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#fff"
                d="M111.042,0h-0.085C81.962,0.042,50.96,42.999,6,42.999c-6,0-6,0-6,0v1h214v-1v-0.015C169.917,42.349,139.492,0.042,111.042,0z"
              />
            </svg>
            <i className="g-font-size-20 g-pos-abs g-bottom-20 g-left-50x g-ml-2 fa fa-angle-double-up" />
          </a>
          {/* <!-- End Go To Top --> */}

          {/* <!-- Copyright --> */}
          <div className="container text-center g-py-30">
            <p className="g-font-size-13 mb-0">
              &copy; 2022 DjammaDev. Tous droits réservés.
            </p>
          </div>
          {/* <!-- End Copyright --> */}
        </footer>
        {/* <!-- End Contact --> */}
      </div>
    );
}

export default Contact;
