import React from "react";

export default function Services(props) {
  return (
    <>
      {/* Services */}
      <div id="services-section" className="g-pt-30 g-overflow-hidden">
        <div className="container g-pt-100">
          {/* Heading */}
          <div className="g-max-width-550 text-center mx-auto g-mb-100">
            <h1 className="text-uppercase g-color-main-light-v1 g-font-weight-600 g-font-size-13 g-letter-spacing-2 mb-4">
              Services
            </h1>
            <p className="g-font-size-16">
              Nanasi Services vous propose des solutions adéquates et
              écologiques pour tous vos besoins d’entretien et de nettoyage.
            </p>
            <p>Nos prestations de qualités sont les suivantes: </p>
          </div>
          {/* End Heading */}

          {/* Icon Blocks */}
          <ul className="row list-inline u-info-v9-1 g-pb-70 mb-0">
            <li className="col-md-4 list-inline-item g-mx-0 g-mb-30">
              {/* Icon Blocks */}
              <div className="u-block-hover text-center g-px-40">
                <div className="g-mb-5">
                  <span className="u-icon-v1 g-width-85 g-height-85 g-color-main g-font-size-50 rounded-circle">
                    <img
                      className="img-fluid w-100"
                      src="./assets/img/icons/maintenance.png"
                      alt="Services"
                    />
                  </span>
                </div>
                <div className="g-mb-25">
                  <span className="u-icon-v2 u-shadow-v22 g-width-25 g-height-25 g-color-primary g-bg-white g-font-size-9 rounded-circle">
                    <i className="fa fa-check" />
                  </span>
                </div>
                <h3 className="g-color-primary g-font-weight-600 g-font-size-17 text-uppercase mb-3">
                  {/* <span className="g-color-main g-font-weight-700">01. </span> */}
                  Entretien
                </h3>
                <p>d’immeubles et copropriétés</p>
                <p>de bureaux et commerces</p>
                <p>Hotellerie et restauration</p>
              </div>
              {/* End Icon Blocks */}
            </li>

            <li className="col-md-4 list-inline-item g-mx-0 g-mb-30">
              {/* Icon Blocks */}
              <div className="u-block-hover text-center g-px-40">
                <div className="g-mb-5">
                  <span className="u-icon-v1 g-width-85 g-height-85 g-color-main g-font-size-50 rounded-circle">
                    <img
                      className="img-fluid w-100"
                      src="./assets/img/icons/cleaning.png"
                      alt="Services"
                    />
                  </span>
                </div>
                <div className="g-mb-25">
                  <span className="u-icon-v2 u-shadow-v22 g-width-25 g-height-25 g-color-white g-bg-primary g-font-size-9 rounded-circle">
                    <i className="fa fa-check" />
                  </span>
                </div>
                <h3 className="g-color-primary g-font-weight-600 g-font-size-17 text-uppercase mb-3">
                  {/* <span className="g-color-main g-font-weight-700">02.</span> */}
                  Nettoyage
                </h3>
                <p>des lieux publics et events</p>
                <p>ponctuel et remise en état</p>
              </div>
              {/* End Icon Blocks */}
            </li>

            <li className="col-md-4 list-inline-item g-mx-0 g-mb-30">
              {/* Icon Blocks */}
              <div className="u-block-hover text-center g-px-40">
                <div className="g-mb-5">
                  <span className="u-icon-v1 g-width-85 g-height-85 g-color-main g-font-size-50 rounded-circle">
                    <img
                      className="img-fluid w-100"
                      src="./assets/img/icons/working.png"
                      alt="Services"
                    />
                  </span>
                </div>
                <div className="g-mb-25">
                  <span className="u-icon-v2 u-shadow-v22 g-width-25 g-height-25 g-color-primary g-bg-white g-font-size-9 rounded-circle">
                    <i className="fa fa-check" />
                  </span>
                </div>
                <h3 className="g-color-primary g-font-weight-600 g-font-size-17 text-uppercase mb-3">
                  {/* <span className="g-color-main g-font-weight-700">03.</span> */}
                  Travaux
                </h3>
                <p>marquage au sol</p>
              </div>
              {/* End Icon Blocks */}
            </li>
          </ul>
          {/* End Icon Blocks */}

          {/* Mockup Image */}
          <img
            className="img-fluid w-100 rounded-lg"
            src="./assets/img/services-img.jpeg"
            alt="Services"
          />
          {/* End Mockup Image */}
        </div>
      </div>
      {/* End Services */}
    </>
  );
}
