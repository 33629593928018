import React from "react";
import About from "./Sections/About"
import Clients from "./Sections/Clients";
import Contact from "./Sections/Contact";
import Header from "./Sections/Header"
import Home from "./Sections/Home"
import Services from "./Sections/Services";


// import logo from './logo-background.png'

// const {$} = window

// export const goTo = (history, path) => {
//     return (e) => {
//         e.preventDefault();
//         history.push(path);
//     }
// }




function App() {
    return (
        <main id={"home-section"} className="App">
            <Header/>
            <Home />
            <Services/>
            <About />
            <Clients />
            <Contact />
        </main>
    );
}

export default App;
