import React from 'react'

const Clients = () => {
    return (
        <div className="clients">
            {/* <!-- News --> */}
            <div id="news-section" className="g-bg-secondary g-py-100">
                <div className="container">
                    {/* <!-- Heading --> */}
                    <div className="g-max-width-550 text-center mx-auto g-mb-10">
                        <h2 className="text-uppercase g-color-main-light-v1 g-font-weight-600 g-font-size-13 g-letter-spacing-2 mb-4">
                            Nos clients
                        </h2>
                        <h2 className="h3 mb-5">Ils nous ont fait confiance !! </h2>
                    </div>
                    {/* <!-- End Heading --> */}

                    <div className="row g-mx-minus-25 g-mb-50">
                        <div className="col-lg-6 g-px-25 g-mb-50">
                            {/* <!-- Blog Grid Modern Blocks --> */}
                            <article className="row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
                                <div
                                    className="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                                    data-bg-img-src="./assets/img/clients/img1.jpeg"
                                />
                                <div
                                    className="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                                    data-bg-img-src="./assets/img/clients/img2.jpeg"
                                />
                            </article>
                            {/* <!-- End Blog Grid Modern Blocks --> */}
                        </div>

                        <div className="col-lg-6 g-px-25 g-mb-50">
                            {/* <!-- Blog Grid Modern Blocks --> */}
                            <article className="row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
                                <div
                                    className="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                                    data-bg-img-src="./assets/img/clients/img6.jpeg"
                                />
                                <div
                                    className="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                                    data-bg-img-src="./assets/img/clients/img5.jpeg"
                                />
                            </article>
                            {/* <!-- End Blog Grid Modern Blocks --> */}
                        </div>

                        <div className="w-100"/>

                        <div className="col-lg-6 g-px-25 g-mb-50">
                            {/* <!-- Blog Grid Modern Blocks --> */}
                            <article className="row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
                                <div
                                    className="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                                    data-bg-img-src="./assets/img/clients/img4.jpeg"
                                />
                                <div
                                    className="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                                    data-bg-img-src="./assets/img/clients/img7.jpeg"
                                />
                            </article>
                            {/* <!-- End Blog Grid Modern Blocks --> */}
                        </div>

                        <div className="col-lg-6 g-px-25 g-mb-50">
                            {/* <!-- Blog Grid Modern Blocks --> */}
                            <article className="row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
                                <div
                                    className="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                                    data-bg-img-src="./assets/img/clients/img3.jpeg"
                                />
                                <div
                                    className="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                                    data-bg-img-src="./assets/img/clients/img8.jpeg"
                                />
                            </article>
                            {/* <!-- End Blog Grid Modern Blocks --> */}
                        </div>
                    </div>

                    <div className="text-center">
                        <a
                            className="btn u-btn-outline-primary g-font-weight-600 g-font-size-12 g-rounded-30 g-py-15 g-px-35"
                            href="#contact-section"
                        >
                            Contacter nous
                        </a>
                    </div>
                </div>
            </div>
            {/* <!-- End News --> */}

            {/* <!-- Clients --> */}
            <div
                className="container u-shadow-v29  g-bg-img-hero g-bg-cover g-bg-black-opacity-0_1--after g-rounded-100 g-rounded-100--after g-mt-50 g-mb-30 g-py-60"
            >
                <div
                    className="js-carousel g-mb-50"
                    data-autoplay="true"
                    data-infinite="true"
                    data-slides-show="6"
                    data-responsive='[{
                 "breakpoint": 1200,
                 "settings": {
                   "slidesToShow": 5
                 }
               }, {
                 "breakpoint": 992,
                 "settings": {
                   "slidesToShow": 4
                 }
               }, {
                 "breakpoint": 768,
                 "settings": {
                   "slidesToShow": 3
                 }
               }, {
                 "breakpoint": 576,
                 "settings": {
                   "slidesToShow": 2
                 }
               }]'
                >
                    <div className="js-slide g-brd-around g-brd-gray-light-v1--hover g-transition-0_2 g-mx-10 g-my-1">
                        <img
                            className="img-fluid g-max-width-170--md mx-auto"
                            src="./assets/img/clients/mge_services.png"
                            alt="client"
                        />
                    </div>

                    <div className="js-slide g-brd-around g-brd-gray-light-v1--hover g-transition-0_2 g-mx-10 g-my-1">
                        <img
                            className="img-fluid g-max-width-170--md mx-auto g-mt-50"
                            src="./assets/img/clients/carreira.png"
                            alt="client"
                        />
                    </div>

                    <div className="js-slide g-brd-around g-brd-gray-light-v1--hover g-transition-0_2 g-mx-10 g-my-1">
                        <img
                            className="img-fluid g-max-width-170--md mx-auto g-mt-50"
                            src="./assets/img/clients/leon.png"
                            alt="client"
                        />
                    </div>

                    <div className="js-slide g-brd-around g-brd-gray-light-v1--hover g-transition-0_2 g-mx-10 g-my-1">
                        <img
                            className="img-fluid g-max-width-170--md mx-auto"
                            src="./assets/img/clients/mge_services.png"
                            alt="client"
                        />
                    </div>

                    <div className="js-slide g-brd-around g-brd-gray-light-v1--hover g-transition-0_2 g-mx-10 g-my-1">
                        <img
                            className="img-fluid g-max-width-170--md mx-auto g-mt-50"
                            src="./assets/img/clients/carreira.png"
                            alt="client"
                        />
                    </div>

                    <div className="js-slide g-brd-around g-brd-gray-light-v1--hover g-transition-0_2 g-mx-10 g-my-1">
                        <img
                            className="img-fluid g-max-width-170--md mx-auto g-mt-50"
                            src="./assets/img/clients/leon.png"
                            alt="client"
                        />
                    </div>

                    <div className="js-slide g-brd-around g-brd-gray-light-v1--hover g-transition-0_2 g-mx-10 g-my-1">
                        <img
                            className="img-fluid g-max-width-170--md mx-auto"
                            src="./assets/img/clients/mge_services.png"
                            alt="client"
                        />
                    </div>

                    <div className="js-slide g-brd-around g-brd-gray-light-v1--hover g-transition-0_2 g-mx-10 g-my-1">
                        <img
                            className="img-fluid g-max-width-170--md mx-auto g-mt-50"
                            src="./assets/img/clients/carreira.png"
                            alt="client"
                        />
                    </div>

                    <div className="js-slide g-brd-around g-brd-gray-light-v1--hover g-transition-0_2 g-mx-10 g-my-1">
                        <img
                            className="img-fluid g-max-width-170--md mx-auto g-mt-50"
                            src="./assets/img/clients/leon.png"
                            alt="client"
                        />
                    </div>
                </div>

                {/* <div
           className="js-carousel g-pos-rel g-z-index-1"
           data-infinite="true"
           data-autoplay="true"
           data-lazy-load="ondemand"
           data-slides-show="6"
           data-responsive='[{
               "breakpoint": 1200,
               "settings": {
                 "slidesToShow": 6
               }
             }, {
               "breakpoint": 992,
               "settings": {
                 "slidesToShow": 4
               }
             }, {
               "breakpoint": 768,
               "settings": {
                 "slidesToShow": 3
               }
             }, {
               "breakpoint": 576,
               "settings": {
                 "slidesToShow": 3
               }
             }, {
               "breakpoint": 446,
               "settings": {
                 "slidesToShow": 2
               }
             }]'
         >
           <div className="js-slide u-block-hover">
             <img
               className="mx-auto g-width-80 u-block-hover__main--white g-opacity-0_8 g-opacity-1--hover g-cursor-pointer"
               data-lazy="./assets/img/clients/carreira.png"
               alt=" description"
             />
           </div>

           <div className="js-slide u-block-hover">
             <img
               className="mx-auto g-width-80 u-block-hover__main--white g-opacity-0_8 g-opacity-1--hover g-cursor-pointer"
               data-lazy="./assets/img/clients/mge_services.jfif"
               alt=" description"
             />
           </div>

           <div className="js-slide u-block-hover">
             <img
               className="mx-auto g-width-80 u-block-hover__main--white g-opacity-0_8 g-opacity-1--hover g-cursor-pointer"
               data-lazy="./assets/img/clients/leon.png"
               alt=" description"
             />
           </div>

           <div className="js-slide u-block-hover">
             <img
               className="mx-auto g-width-80 u-block-hover__main--white g-opacity-0_8 g-opacity-1--hover g-cursor-pointer"
               data-lazy="./assets/img/clients/leon.png"
               alt=" description"
             />
           </div>

           <div className="js-slide u-block-hover">
             <img
               className="mx-auto g-width-80 u-block-hover__main--white g-opacity-0_8 g-opacity-1--hover g-cursor-pointer"
               data-lazy="./assets/img/clients/leon.png"
               alt=" description"
             />
           </div>

           <div className="js-slide u-block-hover">
             <img
               className="mx-auto g-width-80 u-block-hover__main--white g-opacity-0_8 g-opacity-1--hover g-cursor-pointer"
               data-lazy="./assets/img/clients/leon.png"
               alt=" description"
             />
           </div>

           <div className="js-slide u-block-hover">
             <img
               className="mx-auto g-width-80 u-block-hover__main--white g-opacity-0_8 g-opacity-1--hover g-cursor-pointer"
               data-lazy="./assets/img/clients/leon.png"
               alt=" description"
             />
           </div>
         </div> */}
            </div>
        </div>
    );
}

export default Clients
